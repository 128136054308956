import * as React from "react"
import {
  WrapPageElementBrowserArgs,
  WrapRootElementNodeArgs,
  Script,
  ScriptStrategy,
  ServiceWorkerArgs,
} from "gatsby"
import { ThemeProvider } from "styled-components"
import { lightTheme } from "../src/styles/themes"
import GlobalStyles from "../src/styles/globals"
import {
  HistoryContextProvider,
  HistoryInitializer,
} from "../src/context/history"
import "normalize.css"
import "the-new-css-reset/css/reset.css"
import { AnimatePresence } from "framer-motion"
import { PageContext, PageContextType } from "../src/context/page"
import PageContainer from "../src/components/page-container"
import CookiesBanner from "../src/components/cookies-banner"
import HypothesisContextProvider from "../src/context/hypothesis"

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") return

  const sendPageView = () => {
    const pagePath = location ? location.pathname : undefined
    const pageHash = location ? location.hash : undefined

    if (`gtag` in window && typeof window.gtag === "function") {
      window.gtag(`event`, `page_view`, {
        page_path: pagePath,
        page_hash: pageHash,
      })
    }
  }

  const delayOnRouteUpdate = 0

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, delayOnRouteUpdate))
    })
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + delayOnRouteUpdate)
  }
}

export const wrapRootElement = ({ element }: WrapRootElementNodeArgs) => (
  <React.Fragment>
    {process.env.NODE_ENV === "production" ? (
      <React.Fragment>
        {/* No need to respect DNT as it is deprecated and no need for anonymize_ip as it's no longer collected in GA4 */}
        <Script strategy={ScriptStrategy.postHydrate}>
          {`
        const flag = window.localStorage.getItem("gtag_optin")
        const gaDisable = flag !== null ? Boolean(Number(flag)) : true
        window['ga-disable-${process.env.GA_ID}'] = gaDisable;
      `}
        </Script>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_ID}`}
          strategy={ScriptStrategy.postHydrate}
        />
        <Script id="gtag-config" strategy={ScriptStrategy.postHydrate}>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)};
        gtag('js', new Date());
        gtag('config', '${process.env.GA_ID}', { send_page_view: false})
        `}
        </Script>
      </React.Fragment>
    ) : null}
    <HistoryContextProvider>
      <HypothesisContextProvider>{element}</HypothesisContextProvider>
    </HistoryContextProvider>
  </React.Fragment>
)

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<
  Record<string, unknown>,
  PageContextType,
  { back?: boolean; forward?: boolean }
>) => {
  const key = props.uri.endsWith("/") ? props.uri.slice(0, -1) : props.uri

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <HistoryInitializer uri={props.uri}>
          <CookiesBanner />
          <AnimatePresence>
            <PageContainer
              key={key}
              locationState={props.location.state}
              pageId={props.pageContext.id}
            >
              <PageContext.Provider value={props.pageContext}>
                {element}
              </PageContext.Provider>
            </PageContainer>
          </AnimatePresence>
        </HistoryInitializer>
      </ThemeProvider>

      <link
        key="typekit-fonts"
        rel="stylesheet"
        href="https://use.typekit.net/ecy3cze.css"
      ></link>
    </>
  )
}

export const shouldUpdateScroll = () => false

export const onServiceWorkerUpdateReady = async (args: ServiceWorkerArgs) => {
  const permissionResponse = await Notification.requestPermission()
  if (permissionResponse === "granted") {
    await args.serviceWorker.showNotification(
      "Model i metafora - aktualizacja",
      {
        body: "Publikacja została zaktualizowana. Nastąpiło przeładowanie strony by wyświetlić najnowszą wersję.",
      }
    )
  }
  window.location.reload()
}
