import { motion } from "framer-motion"
import { between, up } from "styled-breakpoints"
import styled from "styled-components"

export const Container = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;

  ${between("md", "xl")} {
    scroll-padding-top: 7rem;
  }

  ${up("xl")} {
    scroll-padding-top: 11rem;
  }

  ${up("xxl")} {
    scroll-padding-top: 15rem;
  }
`
