import { useState, useEffect } from "react"

type StoreStateEvent<T> = {
  key: string
  item: T
}

const type = "set-stored-state" as const

export default function useStoredState<T = any>(
  key: string,
  initialValue: T,
  transform: ((item: string) => T) & ((item: T) => string)
) {
  const getLocalStorageItem = () => {
    if (typeof localStorage === "undefined") return initialValue
    const storedItem = localStorage.getItem(key)
    if (!storedItem) return initialValue
    return transform(storedItem)
  }

  const [item, setItem] = useState(getLocalStorageItem)

  const setStoredItem = (item: T) => {
    localStorage.setItem(key, transform(item))
    const event = new CustomEvent<StoreStateEvent<T>>(type, {
      detail: { key, item },
    })
    window.dispatchEvent(event)
  }

  useEffect(() => {
    const listener = (e: CustomEvent<StoreStateEvent<T>>) => {
      if (e.detail.key === key) setItem(e.detail.item)
    }

    window.addEventListener(type, listener as EventListener)
    return () => window.removeEventListener(type, listener as EventListener)
  }, [])

  return [item, setStoredItem, getLocalStorageItem] as const
}
