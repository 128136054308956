import { Script, ScriptStrategy } from "gatsby"
import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
} from "react"
import useStoredState from "../hooks/useStoredState"

interface Context {
  hypothesis: Element | null
  setHypothesis: React.Dispatch<React.SetStateAction<Element | null>>
}

export const HypothesisContext = createContext<Context | undefined>(undefined)

const HypothesisContextProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [optedIn] = useStoredState<boolean>("his_optin", false, transform)
  const [hypothesis, setHypothesis] = useState<Element | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (hypothesis !== null) return

    const interval = setInterval(() => {
      if (hypothesis !== null) return hypothesis

      const el = document.querySelector("hypothesis-sidebar")
      setHypothesis(el)

      // Style the shadow dom
      if (el !== null) {
        const style = document.createElement("style")

        style.innerHTML = `
        .sidebar-container > div > ul {
          background: transparent !important;
        }
        `

        if (el.shadowRoot) {
          el.shadowRoot.appendChild(style)
          el.classList.add("invisible")
        }
      }
    }, 16)

    return () => clearInterval(interval)
  }, [hypothesis, loaded])

  return (
    <HypothesisContext.Provider value={{ hypothesis, setHypothesis }}>
      {children}
      {optedIn ? (
        <Script
          src="https://hypothes.is/embed.js"
          strategy={ScriptStrategy.postHydrate}
          onLoad={() => setLoaded(true)}
        />
      ) : null}
    </HypothesisContext.Provider>
  )
}

export default HypothesisContextProvider

export function useHypothesisContext() {
  const context = useContext(HypothesisContext)

  if (context === undefined)
    throw new Error(
      "useHypothesisContext can not be used outside a HypothesisContextProvider"
    )

  return context
}

export function useHypothesis() {
  const { hypothesis } = useHypothesisContext()

  const isHidden = () => {
    if (!!!hypothesis) return true

    return hypothesis.classList.contains("invisible")
  }

  const hideHypothesis = () => {
    if (!!!hypothesis || isHidden()) return

    hypothesis.classList.add("invisible")
  }

  const showHypothesis = () => {
    if (!!!hypothesis || !isHidden()) return

    hypothesis.classList.remove("invisible")
  }

  return { hypothesis, showHypothesis, hideHypothesis, isHidden }
}

function transform(value: string): boolean
function transform(value: boolean): string
function transform(value: any): any {
  if (typeof value === "string") return Boolean(Number(value))
  if (typeof value === "number") return value ? "1" : "0"
  throw new Error("Item is of wrong type")
}
