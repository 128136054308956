import * as React from "react"
import * as Styled from "./styles"

type Props = {
  pageId: string
  locationState?: {
    back?: boolean | undefined
    forward?: boolean | undefined
    fade?: boolean | undefined
  }
}

const PageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  pageId,
  children,
  locationState,
}) => {
  const { back = false, forward = false, fade = false } = locationState ?? {}

  const x = React.useMemo(() => {
    if (fade) return 0
    if (back) return -window.innerWidth
    if (forward) return window.innerWidth
    return undefined
  }, [back, forward, fade])

  const opacity = React.useMemo(() => {
    if (fade) return 0
    return 1
  }, [fade])

  const duration = React.useMemo(() => {
    if (fade) return 0.5
    return 0.4
  }, [fade])

  const delay = React.useMemo(() => {
    if (fade) return 0.3
    return 0
  }, [fade])

  return (
    <Styled.Container
      id={`page__container--${pageId}`}
      initial={{ x, opacity }}
      animate={{ x: 0, opacity: 1, transition: { delay, duration } }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration, ease: "easeOut" }}
    >
      {children}
    </Styled.Container>
  )
}

export default PageContainer
