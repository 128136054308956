import { between, up } from "styled-breakpoints"
import styled from "styled-components"

const Button = styled.button`
  height: 6.4rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;
  transition: background 0.2s ease-in, color 0.2s ease-in;
  cursor: pointer;

  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
  }

  &:hover,
  &:focus-visible,
  &:active {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
  }

  ${props => ({
    fontWeight: "normal",
    fontSize: "1.7rem",
    lineHeight: "2.4rem",
    color: props.theme.colors.text,
    fontFamily: props.theme.fonts.azoSans,

    [between("md", "xl")(props)]: {
      fontSize: "2rem",
      lineHeight: "3rem",
    },

    [up("xl")(props)]: {
      fontSize: "2.4rem",
      lineHeight: "3.7rem",
    },

    [up("xxl")(props)]: {
      fontSize: "3.4rem",
      lineHeight: "5rem",
    },
  })}
`

export default Button
