import { motion } from "framer-motion"
import { between, down, only, up } from "styled-breakpoints"
import styled from "styled-components"
import Button from "../button"
import { Typography } from "../system/typography"

export const CookieBanner = styled(motion.div).attrs({
  initial: { bottom: "-100%" },
  animate: { bottom: "0%" },
  exit: { bottom: "-100%" },
  transition: { duration: 0.4 },
})`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;

  background-color: ${({ theme }) => theme.colors.blue};
  border-top: 0.2rem solid ${({ theme }) => theme.colors.ivoryWhite};

  display: flex;
  flex-wrap: wrap;

  ${up("md")} {
    & > button + button {
      margin-left: 1.5rem !important;
    }
  }

  padding: 4rem 3.2rem;

  ${between("md", "xl")} {
    padding: 4rem 8.7rem;
  }

  ${only("xl")} {
    padding: 4rem 16.4rem;

    & > * + * {
      margin-left: 1.5rem !important;
    }
  }

  ${up("xxl")} {
    padding: 4rem 14rem;
  }
`

export const CookieText = styled(Typography).attrs({
  variant: "body",
  typo: "azo",
})`
  flex: 3;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  line-height: 2rem;

  ${down("xl")} {
    flex-basis: 100%;
    margin-bottom: 4rem;
  }
`

export const CookieButton = styled(Button)`
  flex: 1;
  height: initial;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  min-height: 5rem;

  margin: auto;

  &:last-of-type {
    background: ${({ theme }) => theme.colors.ivoryWhite};
    color: ${({ theme }) => theme.colors.blue};
  }

  ${down("md")} {
    flex-basis: 100%;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 1.5rem;
    }
  }
`
