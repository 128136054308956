import { createContext, useContext } from "react"

export type PageContextType = {
  id: string
}

export const PageContext = createContext<PageContextType | undefined>(undefined)

export function usePageContext() {
  const context = useContext(PageContext)

  if (context === undefined)
    throw new Error(
      "usePageContext can not be used outside a PageContext.Provider"
    )

  return context
}
