import { GatsbyLinkProps, Link } from "gatsby"
import React, {
  createContext,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

export interface History {
  last: { path: string; type: "sidePage" | "page" } | undefined
  blue: { last: string | undefined; queue: string[] }
  white: string | undefined
}

type HistoryContextProviderProps = PropsWithChildren<{
  history?: History
}>

interface HistoryContextType {
  history: History
  push: (path: string) => void
  pop: () => string | undefined
}

const defaults: HistoryContextType = {
  history: {
    last: undefined,
    blue: { last: undefined, queue: [] },
    white: undefined,
  },
  push: () => {},
  pop: () => undefined,
}

export const HistoryContext = createContext<HistoryContextType>(defaults)

export const HistoryContextProvider: React.FC<HistoryContextProviderProps> = ({
  children,
  history: defaultHistory = defaults.history,
}) => {
  const [history, setHistory] = useState<History>(defaultHistory)

  const push = useCallback<HistoryContextType["push"]>(
    path =>
      setHistory(prev => {
        const type = getPageType(path)
        let white = prev.white
        const blue = prev.blue

        if (type === "page") {
          white = path
        } else {
          blue.last = path
          if (!!prev.last && prev.last.type === "page") blue.queue = []
          blue.queue.push(path)
        }

        return { ...prev, last: { path, type }, white, blue }
      }),
    [setHistory]
  )

  const pop = useCallback<HistoryContextType["pop"]>(() => {
    let path: string | undefined = undefined

    setHistory(prev => {
      const blue = prev.blue
      if (!prev.last) return prev

      let type: "sidePage" | "page" = "sidePage"
      const last = prev.last

      if (prev.last.type === "page") {
        path = blue.last
        if (blue.queue.length > 1) {
          blue.queue.pop()
          blue.last = blue.queue[blue.queue.length - 1]
        }
      } else {
        if (blue.queue.length > 1) {
          blue.queue.pop()
          blue.last = blue.queue[blue.queue.length - 1]
          path = blue.last
        } else {
          path = prev.white
          type = "page"
        }
      }

      if (path) {
        last.path = path
        last.type = type
      }

      return { ...prev, blue }
    })

    return path
  }, [history])

  const context = useMemo(() => ({ history, push, pop }), [history, push, pop])

  return (
    <HistoryContext.Provider value={context}>
      {children}
    </HistoryContext.Provider>
  )
}

export const useHistoryContext = () => {
  const context = useContext(HistoryContext)

  if (typeof context === "undefined")
    throw new Error(
      "useHistoryContext can only be used within HistoryContextProvider"
    )

  return context
}

const formatLink = (src: string) => {
  if (!src.includes("#")) {
    return { link: src.endsWith("/") ? src : src + "/", hash: undefined }
  }

  const [link, hash] = src.split("#")
  return { link: link.endsWith("/") ? link : link + "/", hash: `#${hash}` }
}

export const HistoryLink = forwardRef<any, GatsbyLinkProps<any>>(
  ({ to, ...props }, ref) => {
    const history = useHistoryContext()
    const { link, hash } = formatLink(to)

    return (
      <Link
        to={hash ? to : link}
        {...props}
        ref={ref}
        onClick={e => {
          const path = e.currentTarget.getAttribute("href")
          if (!path) return
          const { link } = formatLink(path)
          history.push(link)
        }}
        state={{ fade: true, ...(props.state ?? {}) }}
      />
    )
  }
)

export const HistoryInitializer: React.FC<
  PropsWithChildren<{ uri: string }>
> = ({ children, uri }) => {
  const { history, push } = useHistoryContext()

  useEffect(() => {
    let isMounted = true
    if (history.last === undefined && isMounted) push(uri)
    return () => {
      isMounted = false
    }
  })

  return <>{children}</>
}

const sidePages = [
  "/",
  "/about",
  "/authors",
  "/map",
  "/toc",
  "/images",
  "/hypothesis",
  "/search",
  "/cookies",
].flatMap(path => (path.length > 1 ? [path, path + "/"] : [path]))

export const getPageType = (path: string): "sidePage" | "page" => {
  for (const page of sidePages) {
    if (page.includes(path)) return "sidePage"
  }
  return "page"
}
