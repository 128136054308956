import { between, up } from "styled-breakpoints"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle<{
  variant?: "light" | "blue"
}>`
  :root {
    --header-height: 5rem;

    ${between("md", "xl")} {
      --header-height: 7rem;
    }

    ${up("xl")} {
      --header-height: 11rem;
    }

    ${up("xxl")} {
      --header-height: 15rem;
    }
  }

  *:focus-visible {
    outline: solid 1px ${({ theme }) => theme.colors.black} !important;
    outline-offset: 0.5rem;
  }

  html { 
    min-height: 100%;
    font-size: 62.5%;
    background: ${({ theme, variant = "light" }) =>
      variant === "light" ? theme.colors.ivoryWhite : theme.colors.blue};

    scroll-behavior: smooth;
    scroll-padding-top: 5rem;
    overflow: hidden;

    ${between("md", "xl")} {
      scroll-padding-top: 7rem;
    }

    ${up("xl")} {
      scroll-padding-top: 11rem;
    }

    ${up("xxl")} {
      scroll-padding-top: 15rem;
    }
  }

  body {
    min-height: 100%;
    font-size: 1.6rem;
    background: ${({ theme, variant = "light" }) =>
      variant === "light" ? theme.colors.ivoryWhite : theme.colors.blue};  
    overflow-x: hidden;
  }

  #__gatsby, #gatsby-focus-wrapper {
    min-height: 100vh;
  }

  
  .invisible {
    display: none !important;
  }
`

export default GlobalStyles
