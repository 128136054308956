import * as React from "react"
import * as Styled from "./style"
import { AnimatePresence } from "framer-motion"
import { navigate } from "gatsby"
import { useHistoryContext } from "../../context/history"
import useStoredState from "../../hooks/useStoredState"

const GTAG_OPTIN_KEY = "gtag_optin"
const HIS_OPTIN_KEY = "his_optin"

const CookiesBanner: React.FC = () => {
  const [optedIn] = useStoredState<boolean>("his_optin", false, transform)
  const [isVisible, setIsVisible] = React.useState(false)
  const { push } = useHistoryContext()

  React.useEffect(() => {
    const gtagOptin = localStorage.getItem(GTAG_OPTIN_KEY)
    const isCookieFlagSet = gtagOptin !== null
    setIsVisible(!isCookieFlagSet)
  }, [optedIn])

  const sendPageView = () => {
    const pagePath = location ? location.pathname : undefined
    const pageHash = location ? location.hash : undefined

    if (`gtag` in window && typeof window.gtag === "function") {
      window.gtag(`event`, `page_view`, {
        page_path: pagePath,
        page_hash: pageHash,
      })
    }
  }

  const consent = () => {
    // reversed due to optout by defaultl
    localStorage.setItem(GTAG_OPTIN_KEY, "0")
    // @ts-ignore
    window[`ga-disable-${process.env.GA_ID}`] = false
    sendPageView()

    localStorage.setItem(HIS_OPTIN_KEY, "1")
    window.dispatchEvent(
      new CustomEvent("set-stored-state", {
        detail: { key: HIS_OPTIN_KEY, item: "1" },
      })
    )
    setIsVisible(false)
  }

  const deny = () => {
    // reversed due to optout by defaultl
    localStorage.setItem(GTAG_OPTIN_KEY, "1")
    // @ts-ignore
    window[`ga-disable-${process.env.GA_ID}`] = true

    localStorage.setItem(HIS_OPTIN_KEY, "0")
    window.dispatchEvent(
      new CustomEvent("set-stored-state", {
        detail: { key: HIS_OPTIN_KEY, item: "0" },
      })
    )
    setIsVisible(false)
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {isVisible && !location.pathname.includes("/cookies") ? (
        <Styled.CookieBanner>
          <Styled.CookieText>
            Strona korzysta z plików cookies w celu zapewnienia pełnej
            funkcjonalności oraz w celach analitycznych. Aby zapoznać się z
            Polityką Cookies lub dostosować ustawienia kliknij „Przejdź do
            ustawień”.
          </Styled.CookieText>
          <Styled.CookieButton
            data-href="/cookies"
            role="link"
            onClick={() => {
              navigate("/cookies")
              push("/cookies")
            }}
          >
            PRZEJDŹ DO USTAWIEŃ
          </Styled.CookieButton>
          <Styled.CookieButton onClick={deny}>ODRZUĆ</Styled.CookieButton>
          <Styled.CookieButton onClick={consent}>
            ZAAKCEPTUJ
          </Styled.CookieButton>
        </Styled.CookieBanner>
      ) : null}
    </AnimatePresence>
  )
}

export default CookiesBanner

function transform(value: string): boolean
function transform(value: boolean): string
function transform(value: any): any {
  if (typeof value === "string") return Boolean(Number(value))
  if (typeof value === "number") return value ? "1" : "0"
  throw new Error("Item is of wrong type")
}
