import { createTheme as createStyledBreakpoints } from "styled-breakpoints"

type Breakpoints = string[] & Partial<typeof breakpointsObject>

const breakpointsObject = {
  xs: "1px",
  sm: "320px",
  md: "768px",
  lg: "1200px",
  xl: "1900px",
  xxl: "2560px",
}

const breakpointsArray: Breakpoints = Object.values(breakpointsObject)
breakpointsArray.sm = breakpointsObject.sm
breakpointsArray.md = breakpointsObject.md
breakpointsArray.lg = breakpointsObject.lg
breakpointsArray.xl = breakpointsObject.xl
breakpointsArray.xxl = breakpointsObject.xxl

const breakpoints = {
  ...createStyledBreakpoints(breakpointsObject),
  breakpoints: breakpointsArray,
}

const base = {
  ...breakpoints,
  fonts: {
    azoSans: '"azo-sans-web", sans-serif',
    kazimirText: '"kazimirtext-2", sans-serif',
  },
  colors: {
    ivoryWhite: "#fffbf7",
    white: "#FFFFFF",
    blue: "#00A8FF",
    black: "#202020",
  },
}

const lightTheme = {
  ...base,
  colors: {
    ...base.colors,
    text: base.colors.black,
    accentText: base.colors.blue,
    icons: base.colors.blue,
  },
}

const blueTheme = {
  ...base,
  colors: {
    ...base.colors,
    text: base.colors.white,
    accentText: base.colors.white,
    icons: base.colors.white,
  },
}

export { lightTheme, blueTheme, breakpointsObject as breakpoints }
