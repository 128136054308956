const {
  wrapRootElement,
  wrapPageElement,
  shouldUpdateScroll,
  onServiceWorkerUpdateReady,
  onRouteUpdate,
} = require("./config/gatsby-browser")

exports.wrapRootElement = wrapRootElement
exports.wrapPageElement = wrapPageElement
exports.shouldUpdateScroll = shouldUpdateScroll
exports.onServiceWorkerUpdateReady = onServiceWorkerUpdateReady
exports.onRouteUpdate = onRouteUpdate
