import styled from "styled-components"
import { between, only, up } from "styled-breakpoints"
import {
  space,
  color,
  layout,
  variant,
  SpaceProps,
  ColorProps,
  LayoutProps,
  typography,
  TypographyProps as SystemTypographyProps,
} from "styled-system"

type TypographyProps = SpaceProps &
  ColorProps &
  LayoutProps &
  SystemTypographyProps & {
    variant?:
      | "body"
      | "h1"
      | "h2"
      | "h3"
      | "caption"
      | "captionSmall"
      | "footnote"
    typo?: "kazimir" | "azo"
  }

export const Typography = styled("p")<TypographyProps>(props => ({
  ...variant({
    prop: "variant",
    variants: {
      body: {
        fontSize: "1.7rem",
        lineHeight: "2.4rem",
        margin: "0 0 0.7rem 0",
        color: props.theme.colors.text,
        fontFamily: props.theme.fonts.kazimirText,

        [between("md", "xl")(props)]: {
          fontSize: "2rem",
          lineHeight: "3rem",
        },

        [up("xl")(props)]: {
          fontSize: "2.4rem",
          lineHeight: "3.7rem",
          margin: "0 0 1rem 0",
        },

        [up("xxl")(props)]: {
          fontSize: "3.4rem",
          lineHeight: "5rem",
          margin: "0 0 2rem 0",
        },
      },

      h1: {
        color: props.theme.colors.accentText,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.7 * 5rem)",
        lineHeight: "calc(0.7 * 6rem)",
        margin: "calc(0.7 * 5rem) 0 calc(0.7 * 5rem) 0",
        fontWeight: "normal",

        [between("md", "xl")(props)]: {
          fontSize: "3.5rem",
          lineHeight: "calc(0.83 * 6rem)",
          margin: "calc(0.83 * 5rem) 0 calc(0.83 * 5rem) 0",
        },

        [up("xl")(props)]: {
          fontSize: "5rem",
          lineHeight: "6rem",
          margin: "5rem 0 5rem 0",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 5rem)",
          lineHeight: "calc(1.41 * 6rem)",
          margin: "calc(1.41 * 5rem) 0 calc(1.41 * 5rem) 0",
        },
      },

      h2: {
        color: props.theme.colors.accentText,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.7 * 4rem)",
        lineHeight: "calc(0.7 * 6rem)",
        margin: "calc(0.7 * 5rem) 0 calc(0.7 * 5rem) 0",

        [between("md", "xl")(props)]: {
          fontSize: "calc(0.83 * 4rem)",
          lineHeight: "calc(0.83 * 6rem)",
          margin: "calc(0.83 * 5rem) 0 calc(0.83 * 5rem) 0",
        },

        [up("xl")(props)]: {
          fontSize: "4rem",
          lineHeight: "6rem",
          margin: "5rem 0 5rem 0",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 4rem)",
          lineHeight: "calc(1.41 * 6rem)",
          margin: "calc(1.41 * 5rem) 0 calc(1.41 * 5rem) 0",
        },
      },

      h3: {
        color: props.theme.colors.accentText,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.83 * 2.7rem)",
        lineHeight: "calc(0.83 * 3.6rem)",
        margin: "calc(0.83 * 5rem) 0 calc(0.83 * 5rem) 0",

        [up("xl")(props)]: {
          fontSize: "2.7rem",
          lineHeight: "3.6rem",
          margin: "5rem 0 5rem 0",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 2.7rem)",
          lineHeight: "calc(1.41 * 3.6rem)",
          margin: "calc(1.41 * 5rem) 0 calc(1.41 * 5rem) 0",
        },
      },

      caption: {
        textTransform: "uppercase",
        color: props.theme.colors.accentText,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.7 * 2rem)",
        lineHeight: "calc(0.7 * 3rem)",
        margin: "calc(0.7 * 1rem) 0 calc(0.7 * 1rem) 0",
        fontWeight: 500,

        [between("md", "xl")(props)]: {
          fontSize: "calc(0.83 * 2rem)",
          lineHeight: "calc(0.83 * 3rem)",
          margin: "calc(0.83 * 1rem) 0 calc(0.83 * 1rem) 0",
        },

        [only("xl")(props)]: {
          fontSize: "2rem",
          lineHeight: "3rem",
          margin: "1rem 0 1rem 0",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 2rem)",
          lineHeight: "calc(1.41 * 3rem)",
          margin: "calc(1.41 * 1rem) 0 calc(1.41 * 1rem) 0",
        },
      },

      captionSmall: {
        textTransform: "uppercase",
        color: props.theme.colors.accentText,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.7 * 1.5rem)",
        lineHeight: "calc(0.7 * 2rem)",
        margin: "calc(0.7 * 1rem) 0 calc(0.7 * 1rem) 0",
        fontWeight: 500,

        [between("md", "xxl")(props)]: {
          fontSize: "1.5rem",
          lineHeight: "2rem",
          margin: "1rem 0 1rem 0",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 1.5rem)",
          lineHeight: "calc(1.41 * 2rem)",
          margin: "calc(1.41 * 1rem) 0 calc(1.41 * 1rem) 0",
        },
      },

      footnote: {
        color: props.theme.colors.accentText,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.7 * 1.2rem)",
        verticalAlign: "super",
        cursor: "pointer",
        lineHeight: "initial",
        margin: 0,

        [between("md", "xl")(props)]: {
          fontSize: "calc(0.83 * 1.2rem)",
        },

        [only("xl")(props)]: {
          fontSize: "1.2rem",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 1.2rem)",
        },
      },

      footnoteContent: {
        color: props.theme.colors.text,
        fontFamily: props.theme.fonts.azoSans,
        fontSize: "calc(0.7 * 2rem)",

        [between("md", "xl")(props)]: {
          fontSize: "calc(0.83 * 2rem)",
        },

        [only("xl")(props)]: {
          fontSize: "2rem",
        },

        [up("xxl")(props)]: {
          fontSize: "calc(1.41 * 2rem)",
        },
      },
    },
  })(props),
  ...variant({
    prop: "typo",
    variants: {
      kazimir: { fontFamily: props.theme.fonts.kazimirText },
      azo: { fontFamily: props.theme.fonts.azoSans },
    },
  })(props),
  ...space(props),
  ...color(props),
  ...layout(props),
  ...typography(props),
}))

Typography.defaultProps = { variant: "body" }
